const logger = console;

type LogLevel = "info" | "error" | "debug" | "warn";

export function logVoice(message: string, logLevel: LogLevel = "info") {
  logger[logLevel](`[VOICE] ${message}`);
}

export function logCampaign(message?: any, { level = "info" }: { level?: LogLevel } = {}, ...optionalParams: any) {
  logger[level](`[CAMPAIGN] ${message}`, ...optionalParams);
}

export default logger;
