import { z } from "zod";

const envSchema = z.object({
  NEXT_PUBLIC_TEST_TENANT_NAME: z.string().optional(),
  NEXT_PUBLIC_CAMPAIGNS_UI_ENABLED: z.enum(["true", "false"]).default("false").transform(v => v === "true"),
  NEXT_PUBLIC_ANALYTICS_UI_ENABLED: z.enum(["true", "false"]).default("false").transform(v => v === "true"),
  NEXT_PUBLIC_HASURA_GRAPHQL_URL: z.string().url(),
  NEXT_PUBLIC_ANALYTICS_URL: z.string().url(),
  NEXT_PUBLIC_ANALYTICS_DASHBOARD_ID: z.string(),
  NEXT_PUBLIC_VERCEL_ENV: z.string().optional(),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: z.string().optional(),
  NEXT_PUBLIC_VERCEL_URL: z.string().optional(),
  NEXT_PUBLIC_NETIC_ASSISTANT_ID: z.string(),
  NEXT_PUBLIC_GOOGLE_CLOUD_MEDIA_DOMAIN: z.string(),
  NEXT_PUBLIC_SERVICE_TITAN_HOSTNAME: z.string(),
});

// Must specify full variable names for the NextJS compiler to bundle
const env = envSchema.parse({
  NEXT_PUBLIC_TEST_TENANT_NAME: process.env.NEXT_PUBLIC_TEST_TENANT_NAME,
  NEXT_PUBLIC_CAMPAIGNS_UI_ENABLED: process.env.NEXT_PUBLIC_CAMPAIGNS_UI_ENABLED,
  NEXT_PUBLIC_ANALYTICS_UI_ENABLED: process.env.NEXT_PUBLIC_ANALYTICS_UI_ENABLED,
  NEXT_PUBLIC_HASURA_GRAPHQL_URL: process.env.NEXT_PUBLIC_HASURA_GRAPHQL_URL,
  NEXT_PUBLIC_ANALYTICS_URL: process.env.NEXT_PUBLIC_ANALYTICS_URL,
  NEXT_PUBLIC_ANALYTICS_DASHBOARD_ID: process.env.NEXT_PUBLIC_ANALYTICS_DASHBOARD_ID,
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
  NEXT_PUBLIC_NETIC_ASSISTANT_ID: process.env.NEXT_PUBLIC_NETIC_ASSISTANT_ID,
  NEXT_PUBLIC_GOOGLE_CLOUD_MEDIA_DOMAIN: process.env.NEXT_PUBLIC_GOOGLE_CLOUD_MEDIA_DOMAIN,
  NEXT_PUBLIC_SERVICE_TITAN_HOSTNAME: process.env.NEXT_PUBLIC_SERVICE_TITAN_HOSTNAME,
});

export default env;
