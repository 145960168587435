"use client";

import Link from "next/link";
import { ReactNode } from "react";
import { usePathname } from "next/navigation";

export default function DashboardTopbarLink(props: { href: string; children?: ReactNode; className?: string }) {
  const pathname = usePathname();

  let isPathActive: boolean | undefined;
  if (props.href.startsWith("/dashboard/conversations")) {
    isPathActive = pathname?.startsWith("/dashboard/conversations");
  } else {
    isPathActive = pathname?.startsWith(props.href);
  }

  const isActiveStyle = isPathActive ? "font-bold text-blue-700 bg-gray-100" : "font-medium text-gray-900 bg-white";
  return (
    <Link
      href={props.href}
      className={`flex flex-row px-4 py-2 text-sm text-center min-w-20 rounded-xl border-gray-200 hover:bg-gray-100 hover:text-blue-700 ${isActiveStyle} ${props.className}`}
    >
      {props.children}
    </Link>
  );
}
